define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/paymentMethods/available/layout',

  'modules/shop.cash-register-retail/collections/currentPaymentMethodItem',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',
  'modules/shop.cash-register-retail/models/selectedCustomer',
  'upx.modules/PaymentModule/models/GiftCard',

  'modules/shop.cash-register-retail/models/keyboard',

  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'upx.modules/PaymentModule/collections/GiftCard',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',

  'modules/shop.cash-register-retail/views/popups/giftcardManagePopup',
  'modules/shop.cash-register-retail/views/popups/messagePopup',
  'modules/shop.cash-register-retail/events/app/fullScreenLoader',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/views/popups/chooseOpenOrder',
  'modules/shop.cash-register-retail/views/popups/appendOrderItemsToOpenOrder',
  'modules/upx/components/upx',
  'modules/shop.cash-register-retail/models/settings/shopPos',
  'modules/shop.common/components/deviceConfig',
], (
  $, _, Backbone, Template,
  PaymentMethodItemCollection, paymentMethodsSettingsModel, SelectedCustomerModel, GiftCardModel,
  KeyboardModel,
  CurrentOrderItemCollection, CashRegisterApi, GiftCardCollection, PaymentMethods,
  GiftcardManagePopupView, MessagePopupView, FullScreenLoaderEvent, Locale,
  ChooseOpenOrderView, AppendItemsView, Upx, ShopPosModel, DeviceConfig,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'available-payment-methods',

  events: {
    'click [data-action="click"]': 'btnClicked',
  },

  modelEvents: {
    all: 'render',
  },

  regions: {
    popup: '[data-region=popup]',
  },

  initialize({
    collection = null,
    customerModel = null,
    totalPriceWt = null,
    iclModel = null,
    orderModel = null,
  }) {
    this.collection = collection || PaymentMethodItemCollection;
    this.customerModel = customerModel || SelectedCustomerModel;
    this.totalPriceWt = totalPriceWt || CurrentOrderItemCollection.getTotalPriceWt();
    this.iclModel = iclModel;
    this.orderModel = orderModel;
  },

  onShow() {
    this.collection.on('all', this.render, this);
    this.customerModel.on('all', this.render, this);
    if (this.iclModel) {
      this.iclModel.on('change', this.render, this);
    }
  },

  onDestroy() {
    this.collection.off('all', this.render, this);
    this.customerModel.off('all', this.render, this);
    if (this.iclModel) {
      this.iclModel.off('change', this.render, this);
    }
  },

  removeGitfcardPayments() {
    const modelsToRemove = [];
    this.collection.each((model) => {
      const id = model.get('id');
      if (
        id.startsWith(this.collection.GIFTCARD_METHOD)
                    || id.startsWith(this.collection.EXTERNAL_GIFTCARD_METHOD)
      ) {
        modelsToRemove.push(model);
      }
    });
    this.collection.remove(modelsToRemove);
  },

  togglePaymentMethod(id) {
    const model = this.collection.get(id);
    if (model) {
      this.removePayment(model);

      if (id === paymentMethodsSettingsModel.PIN_METHOD) {
        // remove pin extra as well
        const pinExtraMethod = paymentMethodsSettingsModel.PIN_EXTRA_METHOD;
        const pinExtraModel = this.collection.get(pinExtraMethod);
        if (pinExtraModel) {
          this.togglePaymentMethod(pinExtraMethod);
        }
      }
    } else if (!this.collection.existsById(id)) {
      this.addPayment(id);

      if (id === paymentMethodsSettingsModel.PIN_EXTRA_METHOD) {
        // add pin add well when extra is seletect
        const pinMethod = paymentMethodsSettingsModel.PIN_METHOD;
        const pinModel = this.collection.get(pinMethod);
        if (!pinModel) {
          this.togglePaymentMethod(pinMethod);
        }
      }

      if (id === paymentMethodsSettingsModel.PIN_METHOD) {
        // disable qr when pin is selected
        const qrMethod = paymentMethodsSettingsModel.QR_CODE_METHOD;
        const qrModel = this.collection.get(qrMethod);
        if (qrModel) {
          this.togglePaymentMethod(qrMethod);
        }
      }
      if (id === paymentMethodsSettingsModel.QR_CODE_METHOD) {
        // disable pin when qr is selected
        const pinMethod = paymentMethodsSettingsModel.PIN_METHOD;
        const pinModel = this.collection.get(pinMethod);
        if (pinModel) {
          this.togglePaymentMethod(pinMethod);
        }
      }
    }
  },

  removePayment(model) {
    CashRegisterApi.logAction('PAYMENT_REMOVED_FROM_ORDER', {
      model: model.toJSON(),
    });
    // If the collection has localStorage,
    // it means that the model can call destroy on itself without throwing errors.
    if (this.collection.localStorage) {
      model.destroy();
    } else {
      this.collection.remove(model);
    }
  },

  addPayment(id) {
    const paymentModel = this.options.paymentProviderMethodCollection.getModelByAlias(id);
    let data = {};
    if (paymentModel) {
      data = {
        provider_id: paymentModel.get('provider_id'),
        provider_method_id: paymentModel.get('id'),
      };
    }
    const saveModel = this.collection.addMethodById(id, data, { save: this.saveMethods });
    CashRegisterApi.logAction('PAYMENT_ADDED_FROM_ORDER', {
      model: saveModel.toJSON(),
    });
  },

  showError(error) {
    const errorView = new MessagePopupView();
    errorView.open(
      error,
    );
  },
  processAppendToOrder(order) {
    if (order.get('table_id')) {
      this.showError(Locale.translate('table_order_cannot_be_added_to_open_order'));
      return;
    }
    if (order.get('repair_id')) {
      this.showError(Locale.translate('repair_cannot_be_added_to_open_order'));
      return;
    }
    let hasGiftMemberCard = false;
    CurrentOrderItemCollection.each((model) => {
      const type = model.get('type');
      if (
        type === CurrentOrderItemCollection.TYPE_GIFTCARD
          || type === CurrentOrderItemCollection.TYPE_TOP_UP
          || type === CurrentOrderItemCollection.TYPE_MEMBERCARD_GIFTCARD
          || type === CurrentOrderItemCollection.TYPE_MEMBERCARD
      ) {
        hasGiftMemberCard = hasGiftMemberCard || true;
      }
    });
    if (hasGiftMemberCard) {
      this.showError(Locale.translate('order_has_a_membercard_or_giftcard_and_cannot_be_added_to_open_order'));
      return;
    }
    const appendView = new AppendItemsView({
      appendToOrder: order,
      order: this.orderModel,
    });
    appendView.open().then(
      () => {
        const def = new $.Deferred();
        const event = new FullScreenLoaderEvent({
          deferred: def,
          title: Locale.translate('adding_items_to_order'),
        });
        event.trigger();
        const order_items = this.orderModel.get('order_items').map(
          (item) => {
            const resultItem = {};
            const allowedFields = [
              'sku', 'name', 'description',
              'ppu', 'ppu_wt', 'before_discount_ppu', 'before_discount_ppu_wt',
              'quantity',
              'extra',
              'product_id', 'shop_product_id', 'tax_rate_id',
            ];
            allowedFields.forEach((name) => {
              if (name in item) {
                resultItem[name] = item[name];
              }
            });
            resultItem.subitems = [];
            const subitems = item.subitems || item.sub_items || [];
            if (subitems.length) {
              subitems.forEach((subitem) => {
                const resultSubitem = {};
                allowedFields.forEach((name) => {
                  if (name in subitem) {
                    resultSubitem[name] = subitem[name];
                  }
                });
                resultItem.subitems.push(resultSubitem);
              });
            }
            return resultItem;
          },
        );
        Upx.call('ShopModule', 'newOrderItemsWithPickUpInStoreOrderShipment',
          {
            fields: {
              order_items,
              order_id: order.get('id'),
              force_negative_stock_if_missing: 'true',
            },
          }).then(
          (shipment_id) => {
            CashRegisterApi.logAction('APPEND_TO_ORDER_SUCCESS', {
              order_items: this.orderModel.get('order_items'),
              order_id: order.get('id'),
              shipment_id,
            });

            def.resolve();

            PaymentMethodItemCollection.clear();
            SelectedCustomerModel.unload();
            CurrentOrderItemCollection.clear();

            // this will cancel the current order
            Backbone.history.navigate('checkout', { trigger: true });
          },
          () => {
            def.reject();
            this.showError(Locale.translate('failed_to_add_items_to_existing_order'));
          },
        );
      },
      // cancel not interesting, nothing happes
    );
  },

  togglePayLater() {
    const id = this.collection.PAYLATER_METHOD;
    const model = this.collection.get(id);
    if (model) {
      this.removePayment(model);
    } else if (!this.collection.existsById(id)) {
      if (this.orderModel) {
        if (ShopPosModel.get('mode') === DeviceConfig.MODE_Hospitality) {
          // horeca required direct printing which is not supported
          this.addPayment(id);
        } else {
          const view = new ChooseOpenOrderView();
          view.open(
            this.customerModel.get('id'),
            this.orderModel,
            this.iclModel && this.iclModel.get('selected'),
          ).then(
            (order) => {
              if (order.get('id') !== this.orderModel.get('id')) {
                this.processAppendToOrder(order);
              } else {
                this.addPayment(id);
              }
            },
          );
        }
      } else {
        console.warn('togglePayLater: No this.orderModel set, cannot detect current order');
        this.addPayment(id);
      }
    }
  },

  btnClicked(e) {
    // increases the touch speed
    e.stopPropagation();
    e.preventDefault();

    const $el = $(e.currentTarget);
    $el.blur();

    KeyboardModel.resetMode();

    const self = this;
    const id = $el.data('id');
    if (id === this.collection.GIFTCARD_METHOD) {
      this.checkCustomerGiftcards()
        .then(() => {
          const view = new GiftcardManagePopupView({
            collection: this.collection,
          });
          const viewDef = new $.Deferred();
          view.open(viewDef);

          viewDef.then((data) => {
            self.removeGitfcardPayments();

            const { collection } = data;
            collection.each((model) => {
              let payment = null;
              if (model.get('type') === this.collection.EXTERNAL_GIFTCARD_METHOD) {
                payment = this.collection.addExternalGiftCardMethod(
                  model.get('code'),
                  model.get('balance'),
                  model.get('provider_method.title'),
                  model.get('provider_method.image_url'),
                  model.get('provider_method.id'),
                  model.get('provider_method.provider_id'),
                  model.get('pin'),
                  model.get('provider_method.max_amount'),
                  { save: this.saveMethods },
                );
              } else if (model.get('type') === this.collection.GIFTCARD_METHOD) {
                payment = this.collection.addGiftCardMethod(
                  model.get('code'),
                  model.get('balance'),
                  model.get('gift_card_id'),
                  { save: this.saveMethods },
                );
              } else {
                console.error(`Unknown giftcard type ${model.get('type')}`);
              }
              if (payment) {
                // we add the original data,
                // so it can be retrieved in the giftcard popup
                payment.set('original_data', model.toJSON());
                if (this.saveMethods) payment.save();
                CashRegisterApi.logAction('PAYMENT_ADDED_FROM_ORDER', {
                  model: payment.toJSON(),
                });
              }
            });
          });
        });
    } else if (id === this.collection.PAYLATER_METHOD) {
      this.togglePayLater();
    } else {
      this.togglePaymentMethod(id);
    }
  },

  getCollection() {
    const hasCustomer = this.customerModel.has('id');
    const hasInvoicePayment = !!this.collection.get(this.collection.INVOICE_METHOD);
    const hasOnOrderPayment = !!this.collection.get(this.collection.PAYLATER_METHOD);
    const hasPinExtraPayment = !!this.collection.get(this.collection.PIN_EXTRA_METHOD);
    const orderTotalWt = parseFloat(this.totalPriceWt);
    const prepaymentMethods = [
      this.collection.PIN_METHOD,
      this.collection.GIFTCARD_METHOD,
      this.collection.CASH_METHOD,
      this.collection.QR_CODE_METHOD,
      this.collection.OTHER_METHOD,
    ];
    const payLaterMethods = [
      this.collection.INVOICE_METHOD,
      this.collection.PAYLATER_METHOD,
    ];
    return paymentMethodsSettingsModel.getEnabledPaymentMethodCollection()
      .map((model) => {
        const modelData = model.toJSON();
        const { id, requires_customer } = modelData;

        // Check for the initial state
        let disabled = requires_customer;
        if (requires_customer && hasCustomer) {
          disabled = false;
        }

        // On order / invoice allow to pre-pay with certain methods
        if ((hasOnOrderPayment || hasInvoicePayment) && prepaymentMethods.indexOf(id) === -1) {
          disabled = true;
        } else if (
          (
            hasOnOrderPayment
            || hasInvoicePayment
            || hasPinExtraPayment
          )
          && payLaterMethods.indexOf(id) !== -1
        ) {
          disabled = true;
        }

        // Check if a giftcard payment is added
        let selected = false;
        if (id === this.collection.GIFTCARD_METHOD) {
          selected = this.collection.filter((paymentModel) => {
            const methodId = paymentModel.get('id');
            return methodId.startsWith(this.collection.EXTERNAL_GIFTCARD_METHOD)
                                || methodId.startsWith(this.collection.GIFTCARD_METHOD);
          }).length > 0;
        } else if (this.collection.get(id)) {
          selected = true;
          disabled = false;
        }

        // Only allow cash payment with certain order values
        if (orderTotalWt <= 0.0 && id !== this.collection.CASH_METHOD) {
          disabled = true;
        }

        return {
          id,
          requiresCustomer: model.get('requires_customer'),
          disabled,
          selected,
          icon: paymentMethodsSettingsModel.getIconByMethod(id),
          name: paymentMethodsSettingsModel.getNameByMethod(id),
        };
      });
  },

  serializeData() {
    return {
      collection: this.getCollection(),
      iclModel: this.iclModel ? this.iclModel.toJSON() : null,
    };
  },

  /**
         * Check if the customer has any giftcards.
         * If so, we add them
         */
  checkCustomerGiftcards() {
    const def = new $.Deferred();
    if (this.customerModel.has('id')) {
      const event = new FullScreenLoaderEvent({
        deferred: def,
        title: Locale.translate('checking_if_customer_has_gift_cards_payment_cards'),
      });
      event.trigger();

      const giftCardCollection = new GiftCardCollection();
      const giftCardParameters = {
        start: 0,
        limit: 0,
        sort: [{
          name: 'date_created',
          dir: 'asc',
        }],
        filters: [
          {
            name: 'balance__>',
            val: '0',
          },
          {
            name: 'in_stock__=',
            val: '0',
          },
          {
            name: 'is_anonymous__=',
            val: false,
          },
          {
            name: 'relation_data_id__=',
            val: this.customerModel.get('id'),
          },
        ],
      };

      giftCardCollection.fetch({ params: giftCardParameters })
        .then(() => {
          giftCardCollection.each((model) => {
            model.set({
              type: PaymentMethods.GIFTCARD_METHOD,
              gift_card_id: model.get('id'),
              id: `${PaymentMethods.GIFTCARD_METHOD}:${model.get('code')}`,
            });
            const payment = this.collection.addGiftCardMethod(
              model.get('code'),
              model.get('balance'),
              model.get('id'),
            );
            payment.set('original_data', model.toJSON());
          });
          def.resolve();
        }, def.reject);
    } else {
      def.resolve();
    }

    return def;
  },

}));
