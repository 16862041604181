define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/paymentScreens/order/itemTotals',
  'modules/common/components/historyBreadcrumb',
], (
  $, _, Backbone, Template,
  HistoryBreadcrumb,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  initialize({ model, backRoute }) {
    this.model = model;
    this.backRoute = backRoute;
  },

  events: {
    'click [data-action=back]': 'backClicked',
  },

  backClicked() {
    if (this.backRoute) {
      HistoryBreadcrumb.goto(this.backRoute);
    } else {
      HistoryBreadcrumb.goBack();
    }
  },

  serializeData() {
    return {
      value_outstanding_wt: this.model.get('value_outstanding_wt'),
      currency_iso3: this.model.get('currency_iso3'),
    };
  },

}));
