define([
  'jquery',
  'underscore',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/payments/manualRefundPopup.hbs',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/common/components/currency',

  'modules/admin/behaviors/loader',
  'modules/shop.cash-register-retail/components/payment',
  'modules/shop.cash-register-retail/components/toaster',
], (
  $, _, PopupView, Template, Locale, CashRegisterApi, Currency,
  Loader, Payment, Toaster,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--confirm',

  ui: {
    confirm: '[data-action="confirm"]',
    close: '[data-action="close"]',
    title: '[data-ui="title"]',
    message: '[data-ui="message"]',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },
  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',

    'click @ui.confirm': 'confirmClicked',
    'touchend @ui.confirm': 'confirmClicked',
  },

  initialize({
    orderId,
    invoiceId,
    paymentMethodCollection,
  }) {
    this.orderId = orderId;
    this.invoiceId = invoiceId;
    this.paymentMethodCollection = paymentMethodCollection;

    if (!orderId && !invoiceId) console.error('Missing invoiceId or orderid');
  },

  open(def) {
    def = this.renderInFloatingRegion(def);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/payments/manualRefundPopup',
    });

    this.openPopup();

    return def;
  },

  async confirm() {
    const def = this.loader.startLoader();
    def.then(
      () => this.close(),
      (resp) => Toaster.error(resp.error),
    );

    try {
      if (this.orderId) {
        await Payment.processCashRefundForLockedOrderItems({
          orderId: this.orderId,
          paymentMethodCollection: this.paymentMethodCollection,
        });
      }

      if (this.invoiceId) {
        await Payment.processCashRefundForLockedInvoiceItems({
          invoiceId: this.invoiceId,
          paymentMethodCollection: this.paymentMethodCollection,
        });
      }

      def.resolve();
    } catch (err) {
      def.reject(err);
    }
  },

  serializeData() {
    return {
      total: Currency.format('EUR', Payment.getTotalWtOfLockedItems(this.paymentMethodCollection)),
    };
  },
}));
