define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/paymentScreens/invoice/layout',

  'modules/shop.cash-register-retail/views/paymentMethods/available/swappable',
  'modules/shop.cash-register-retail/views/customers/selection/selected',
  './itemTotals',

  'modules/shop.cash-register-retail/views/paymentMethods/total',
  'modules/shop.cash-register-retail/views/paymentMethods/list/layout',
  './invoiceList',
  './payButton',

  'modules/shop.cash-register-retail/views/payments/processingCollection',
  'modules/shop.cash-register-retail/views/popups/paymentErrorPopup',
  'modules/shop.cash-register-retail/views/payments/manualRefundPopup',
  'modules/shop.cash-register-retail/views/keypads/main',

  'modules/shop.cash-register-retail/components/payment',
  'modules/common/components/locale',
  'modules/common/components/promisify',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/shop.cash-register-retail/components/cashRegisterApi',

  'modules/shop.cash-register-retail/events/app/fullScreenLoader',

  'modules/shop.cash-register-retail/collections/paymentMethodItem',

  'modules/shop.cash-register-retail/models/keyboard',
  'modules/common/components/historyBreadcrumb',
  'modules/common/collections/DeepModelCollection',
], (
  $, _, Backbone, Template,
  AvailablePaymentMethodsView, CustomerView, ItemTotalsView,
  TotalPaymentMethodsView, ListPaymentMethodsView, InvoiceListView, PayButton,
  ProcessingCollectionView, PaymentErrorPopup, RefundPopupView, KeypadView,
  Payment, Locale, Promisify, Toaster, CashRegisterApi,
  FullScreenLoaderEvent,
  PaymentMethodCollection,
  KeyboardModel, HistoryBreadcrumb, DeepModelCollection,
) => Backbone.Marionette.LayoutView.extend({

  className: 'payment-screen-invoice pos-page-content payment-screen-grid',

  template: Template,

  regions: {
    customer: '[data-region=customer]',
    paymentMethods: '[data-region=payment-methods]',
    payments: '[data-region=payments]',
    paymentTotals: '[data-region=payment-totals]',
    items: '[data-region=items]',
    itemTotals: '[data-region=item-totals]',
    keypad: '[data-region=keypad]',
    pay: '[data-region=pay]',
  },

  onRender() {
    this.renderCustomer();
    this.renderPayments();
    this.renderPaymentMethods();
    this.renderPaymentTotals();
    this.renderItems();
    this.renderItemTotals();
    this.renderKeypad();
    this.renderPay();
  },

  initialize({ customerModel, invoiceStatModel }) {
    this.paymentMethodCollection = new PaymentMethodCollection();
    this.paymentMethodCollection.setTotalPriceWt(this.model.get('value_outstanding_wt'));

    this.customerModel = customerModel;
    this.invoiceStatModel = invoiceStatModel;
    this.logCollection = new Backbone.Collection();
  },

  renderCustomer() {
    const view = new CustomerView({
      model: this.customerModel,
      allowChange: false,
    });
    const region = this.getRegion('customer');
    region.show(view);
  },

  renderPayments() {
    const view = new ListPaymentMethodsView({
      collection: this.paymentMethodCollection,
      shopManualRefundPopup: () => this.shopManualRefundPopup(),
    });
    const region = this.getRegion('payments');
    region.show(view);
  },

  renderPaymentMethods() {
    const view = new AvailablePaymentMethodsView({
      customerModel: this.customerModel,
      collection: this.paymentMethodCollection,
      totalPriceWt: this.model.get('value_outstanding_wt'),
    });
    const region = this.getRegion('paymentMethods');
    region.show(view);
  },

  renderPaymentTotals() {
    const view = new TotalPaymentMethodsView({
      totalPriceWt: this.model.get('value_outstanding_wt'),
      collection: this.paymentMethodCollection,
    });
    const region = this.getRegion('paymentTotals');
    region.show(view);
  },

  renderItems() {
    const view = new InvoiceListView({
      model: this.model,
    });
    const region = this.getRegion('items');
    region.show(view);
  },

  renderItemTotals() {
    const view = new ItemTotalsView({
      model: this.model,
      invoiceStatModel: this.invoiceStatModel,
      customerModel: this.customerModel,
    });
    const region = this.getRegion('itemTotals');
    region.show(view);
  },

  renderKeypad() {
    const view = new KeypadView();
    const region = this.getRegion('keypad');
    region.show(view);
  },

  renderPay() {
    const view = new PayButton({
      model: this.model,
      collection: this.paymentMethodCollection,
    });
    const region = this.getRegion('pay');
    region.show(view);
  },

  childEvents: {
    'pay:clicked': 'payClicked',
  },

  async payClicked() {
    const fullScreenLoaderDeferred = new $.Deferred();
    try {
      await this.processPaymentMethods(fullScreenLoaderDeferred);
      await this.finalize();
    } catch (error) {
      fullScreenLoaderDeferred.reject();
      console.error(error);
    }
  },

  async processPaymentMethods(fullScreenLoaderDeferred) {
    this.logCollection = new Backbone.Collection();
    const processingView = new ProcessingCollectionView({
      collection: this.logCollection,
      number: this.model.get('number'),
      type: ProcessingCollectionView.TYPE_OUTSTANDING_INVOICE,
    });

    const event = new FullScreenLoaderEvent({
      deferred: fullScreenLoaderDeferred,
      statusView: processingView,
      title: Locale.translate('processing_invoice_{number}', { number: this.model.get('number') }),
      extraClassName: 'payments-processing',
    });
    event.trigger();

    const log = processingView.full(this.model);
    let paymentResults = null;
    try {
      let relation_data_id = null;
      if (this.model.get('relation_data_from_id') !== this.model.get('relation_data_to_id')) {
        relation_data_id = this.model.get('relation_data_to_id');
      }
      const paymentProducts = Payment.getPaymentProducts(new DeepModelCollection(this.model.get('invoice_rows')));
      paymentResults = await Payment.processPaymentMethods({
        paymentMethodCollection: this.paymentMethodCollection,
        totalValueWt: this.model.get('value_outstanding_wt'),
        processingView,
        paymentProducts,
        relation_data_id,
      });

      await Payment.attachInvoicePayments({
        paymentResults,
        processingView,
        invoiceId: this.model.get('id'),
      });

      log.success();
    } catch (error) {
      log.error(error.error);
      fullScreenLoaderDeferred.reject(error);

      Payment.processFailedInvoicePayments({
        error,
        processingView,
        invoiceId: this.model.get('id'),
        number: this.model.get('number'),
        paymentMethodCollection: this.paymentMethodCollection,
      });

      processingView.stopAllTimes();

      // rejects def then breaks here if there is an error
      throw error;
    }

    await Payment.processInvoiceSignature(
      this.model, processingView, paymentResults, this.paymentMethodCollection,
    );

    fullScreenLoaderDeferred.resolve();
  },

  async finalize() {
    // clean the protector, so we can always leave the page
    window.onbeforeunload = null;
    // pop the payment route from stack, so next time the goBack is called if goes to correct route
    const backRoute = HistoryBreadcrumb.getBackRoute();
    this.triggerMethod('layout:swap', 'success', {
      logCollection: this.logCollection,
      backRoute,
      paymentMethodCollection: this.paymentMethodCollection,
    });
  },

  onShow() {
    KeyboardModel.resetMode();
    this.addRefundCashPayment();

    window.onbeforeunload = () => this.pageProtector();
  },

  addRefundCashPayment() {
    const amount = this.model.get('value_outstanding_wt');
    if (parseFloat(amount) <= 0) {
      this.paymentMethodCollection.addMethodById(this.paymentMethodCollection.CASH_METHOD);
    }
  },

  shopManualRefundPopup() {
    const view = new RefundPopupView({
      invoiceId: this.model.get('id'),
      paymentMethodCollection: this.paymentMethodCollection,
    });
    view.open();
  },

  pageProtector() {
    const hasPaid = Payment.getTotalWtOfLockedItems(this.paymentMethodCollection) !== '0.00';
    if (hasPaid) {
      this.shopManualRefundPopup();
      return true;
    }
    return null;
  },

  onDestroy() {
    // clean protectors
    window.onbeforeunload = null;
  },

}));
