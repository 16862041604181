define([
  'jquery',
  'backbone',
  'modules/shop.cash-register-retail/templates/payments/qrOverlay.hbs',
  'modules/shop.cash-register-retail/views/customerScreens/orderScreen/qrPayment.js',
  'modules/mobile/views/popup',

], (
  $, Backbone, Template, QrPayment, PopupView,
) => PopupView.extend({

  className: 'dialog',

  template: Template,

  regions: {
    qrContent: '#qr-content',
  },

  ui: {
    close: '[data-action="close"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
  },

  initialize(options) {
    PopupView.prototype.initialize.call(this, options);
  },

  onShow() {
    this.getRegion('qrContent').show(new QrPayment());
  },

  open(def = null) {
    def = this.renderInFloatingRegion(def);

    this.logOpenAction('modules/shop.cash-register-retail/views/payments/qrOverlay.js');
    this.openPopup();

    return def;
  },

}));
