define([
  'jquery',
  'backbone',
  'modules/shop.cash-register-retail/templates/payments/processingItem.hbs',
  'modules/shop.cash-register-retail/views/payments/qrOverlay.js',
  'modules/common/components/moment',
  'modules/common/components/locale',
], (
  $, Backbone, Template, QrOverlayView, Moment, Locale,
) => Backbone.Marionette.ItemView.extend({

  tagName: 'tbody',
  className: 'payments-processingItem',

  template: Template,

  ui: {
    timer: '[data-ui="timer"]',

    cancelButton: 'button[data-action="cancel"]',
    showLargeButton: 'button[data-action="show-large"]',
  },

  events: {
    'click @ui.cancelButton': 'cancelClicked',
    'click @ui.showLargeButton': 'showLargeClicked',
  },

  modelEvents: {
    change: 'render',
  },

  onRender() {
    this.initializeTimer();
    this.renderSuccessCls();
  },

  onDestroy() {
    this.cleanTimer();
    this.cleanQrPopup();
  },

  renderSuccessCls() {
    const success = this.model.get('success');
    if (typeof success === 'boolean') {
      if (success) {
        this.$el.addClass('success').removeClass('error');
      } else {
        this.$el.addClass('error').removeClass('success');
      }
    }
  },

  cancelClicked() {
    const def = new $.Deferred();

    this.ui.cancelButton.prop('disabled', true);
    this.model.trigger(
      'payment:cancel',
      def,
    );
    def.always(() => {
      if (!this.isDestroyed) {
        this.ui.cancelButton.prop('disabled', false);
      }
    });
  },

  showLargeClicked() {
    this.qrView = new QrOverlayView();
    this.qrView.open().always(() => { this.qrView = null; });
  },
  cleanQrPopup() {
    if (this.qrView) {
      this.qrView.close();
    }
  },
  initializeTimer() {
    this.cleanTimer(); // in case it`s re-rendered
    // render the timer
    const startTime = this.model.get('startTime');
    if (startTime) {
      const endTime = this.model.get('endTime');
      if (endTime) {
        if (this.model.get('success')) {
          // finished success -> show seconds
          this.renderTimerValue(this.getTimer(startTime, endTime));
        } else {
          // error -> show time of a day
          this.renderTimerValue(
            Locale.translate('at_{0}', new Moment(endTime).format('HH:mm')),
          );
        }
      } else {
        // running timer
        this.timer = setInterval(() => {
          this.renderTimerValue(this.getTimer(startTime));
        }, 100);
      }
    }
  },

  renderTimerValue(value) {
    this.ui.timer.text(value);
  },

  cleanTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },

  getTimer(startTime, endTime) {
    if (!endTime) {
      endTime = (new Date()).getTime();
    }
    let diff = endTime - startTime;
    diff /= 1000;
    diff = diff.toFixed(1);
    return Locale.translate('{0}s', diff); // time in secconds
  },

}));
