define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/paymentMethods/list/cellDelete',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  events: {
    'click button': 'buttonClicked',
  },

  tagName: 'td',

  className() {
    return `${this.model.get('id')} text-center`;
  },

  buttonClicked() {
    this.options.paymentModel.destroy();
  },

}));
