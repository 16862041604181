define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/paymentScreens/invoice/invoiceList',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  serializeData() {
    return {
      invoices: [this.model.toJSON()],
    };
  },

}));
