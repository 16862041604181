define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/views/swappableWithLoading',

  './layout',

  'modules/shop.cash-register-retail/collections/upx/PaymentProviderMethod',
], (
  $, _, Backbone, SwappableView,
  View,
  PaymentProviderMethodCollection,
) => SwappableView.extend({

  initialize(options) {
    SwappableView.prototype.initialize.call(this, options);

    this.setMainView(View, {
      collection: options.collection,
      showPayLaterMethods: options.showPayLaterMethods,
      orderModel: options.orderModel,
      saveMethods: options.saveMethods,
      customerModel: options.customerModel,
      totalPriceWt: options.totalPriceWt,
      iclModel: options.iclModel,
    });
  },

  loadSharedData(data) {
    const def = new $.Deferred();

    $.when(
      PaymentProviderMethodCollection.load(),
    ).then(() => {
      data.paymentProviderMethodCollection = PaymentProviderMethodCollection;
      def.resolve();
    });

    return def;
  },

  togglePaymentMethod(id) {
    const view = this.getCurrentView();
    if (view) {
      view.togglePaymentMethod(id);
    }
  },

  onRender() {
    this.$el.find('[js-region]').css('height', '100%');
  },

}));
