define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/payments/layout.hbs',

  'modules/shop.cash-register-retail/models/keyboard',
  'modules/upx/components/upx',
  'modules/shop.cash-register-retail/collections/paymentResults',
  'modules/shop.cash-register-retail/collections/currentPaymentMethodItem',
  'modules/shop.cash-register-retail/collections/TaxRate',

  'modules/shop.cash-register-retail/views/customers/selection/swappable',
  'modules/shop.cash-register-retail/views/products/list/layout',
  'modules/shop.cash-register-retail/views/products/totals',
  'modules/shop.cash-register-retail/views/keypads/main',

  'modules/shop.cash-register-retail/views/paymentMethods/available/swappable',
  'modules/shop.cash-register-retail/views/paymentMethods/list/layout',
  'modules/shop.cash-register-retail/views/paymentMethods/total',
  'modules/shop.cash-register-retail/views/payments/processingCollection',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',
  'modules/shop.cash-register-retail/views/payments/cashierDisplay',

  'modules/shop.cash-register-retail/views/popups/messagePopup',
  'modules/shop.cash-register-retail/views/popups/paymentErrorPopup',
  'modules/shop.cash-register-retail/views/payments/manualRefundPopup',
  'modules/shop.cash-register-retail/views/shippingModal/partialPopup',

  'modules/shop.cash-register-retail/models/selectedCustomer',
  'modules/shop.cash-register-retail/components/icp',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/order',
  'modules/shop.cash-register-retail/components/payment',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/common/components/currency',
  'modules/shop.cash-register-retail/models/upx/Order',

  'modules/shop.cash-register-retail/collections/currentPaymentMethodItem',
  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/shop.cash-register-retail/collections/tab',
  'modules/shop.cash-register-retail/collections/loadable/tables',
  'upx.modules/ShippingModule/collections/ShippingMethod',

  'upx.modules/ShopModule/models/OrderVar',
  'upx.modules/ShopModule/models/TableOrder',
  'modules/shop.cash-register-retail/components/repair',
  'modules/shop.cash-register-retail/models/settings/shopPos',

  'modules/shop.cash-register-retail/events/app/fullScreenLoader',
  'modules/shop.cash-register-retail/components/refundOrder',
  'modules/shop.cash-register-retail/collections/lastOrders',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/components/printing',

  'modules/shop.cash-register-retail/models/settings/terminal',
], (
  $, _, Backbone, Template,
  KeyboardModel, Upx, PaymentResultsCollection, PaymentMethodCollection, TaxRate,
  CustomerSelectionView, ProductListView, ProductTotalsView, KeypadPercentageView,
  AvailablePaymentView, PaymentListView, PaymentTotalView, ProcessingCollectionView, PaymentMethods, CashierDisplayView,
  MessagePopupView, PaymentErrorPopup, RefundPopupView, PartialPopupView,
  SelectedCustomerModel, IcpComponent,
  Locale, Order, Payment, Toaster, Currency, OrderModel,
  PaymentMethodItemCollection, OrderItemCollection, TabCollection, Tables, ShippingMethodCollection,
  OrderVarModel, TableOrderModel, Repair, ShopPos,
  FullScreenLoaderEvent, RefundOrderComponent, LastOrdersCollection, CashRegisterApi, Printing,
  TerminalSetting,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'payment',

  regions: {
    customer: '[data-region="customer"]',
    'payment-methods': '[data-region="payment-methods"]',

    'product-list': '[data-region="product-list"]',
    'product-totals': '[data-region="product-totals"]',

    'payment-list': '[data-region="payment-list"]',
    'payment-totals': '[data-region="payment-totals"]',

    keypad: '[data-region="keypad"]',

    popup: '[data-region="popup"]',
  },

  ui: {
    pay: '[data-ui="pay"]',
    description: '[data-ui="description"]',
    delivery: '[data-ui=delivery]',
  },

  events: {
    'click [data-action="pay"]': 'payClicked',
    'click [data-action="icl-click"]': 'iclClicked',
    'click @ui.delivery': 'deliveryClicked',
    'change @ui.description': 'syncDescription',
  },

  initialize({
    tableId = null, repairModel = null, isQuick = false,
  }) {
    this.tableId = tableId;
    if (repairModel) {
      this.repairId = repairModel.get('id');
      this.repairNumber = repairModel.get('number');
    }
    // TODO [ian@8/14/20]: Remove when tabs are being remove
    this.tabId = this.options.tabId || false;
    this.keyboardModel = KeyboardModel;
    this.orderDef = null;
    this.order = new OrderModel();
    this.isQuick = !!isQuick;
    this.isProcessingOrder = false;
    this.iclModel = new Backbone.Model({
      disabled: false,
      selected: false,
    });
    this.orderItemCollection = OrderItemCollection.cloneCollection();
    // Ensure the partial delivery here, so it ensures the partial delivery here.
    // Not not after its rendered, causing thousands of updates
    this.ensurePartialDelivery();
    this.orderCreated = false;
  },

  isIcl() {
    return !!this.iclModel.get('selected');
  },

  createInitialOrder() {
    const def = new $.Deferred();
    let customer_reference = null;

    if (this.repairNumber) {
      customer_reference = Locale.translate('repair_{number}', {
        number: this.repairNumber,
      });
    }

    Order.createInitialOrder(this.order, {
      is_concept: true,
      customer_reference,
    }).then(
      (model) => {
        if (Order.isTotalCorrectWithRemote(model)) {
          this.orderCreated = true;
          def.resolve(model);
        } else {
          const local_order_value_wt = this.orderItemCollection.getTotalPriceWt();
          CashRegisterApi.logAction('ORDER_MISMATCH_WITH_REMOTE', {
            local_order_value_wt,
            order_value_wt: model.get('value_wt'),
            order: model.toJSON(),
            OrderItemCollection: this.orderItemCollection.toJSON(),
          });

          this.showError({
            error: Locale.translate('the_order_amount_{0}_is_not_the_same_as_the_backoffice_amount_{1}', [local_order_value_wt, model.get('value_wt')]),
          });
          def.reject();
        }
      },
      (error) => {
        console.error(error);
        this.showError(error);
        def.reject();
      },
    );
    return def;
  },

  iclClicked() {
    if (this.isIcl()) {
      return;
    }

    const errors = IcpComponent.getIcpErrors();
    if (errors.length) {
      const view = new MessagePopupView();
      view.open(
        Locale.translate('icl_cannot_be_applied'),
        null,
        `${errors.join('. ')}.`,
      );

      return;
    }
    const iclTaxId = TaxRate
      .getByAliasAndCountry('special_community_intra', 'EU')
      .get('id');
    if (this.orderSyncReady()) {
      const orderDef = new $.Deferred();
      this.setOrderSyncDeferred(orderDef);

      (new FullScreenLoaderEvent({
        deferred: orderDef,
        title: Locale.translate('recalculating_icl_values'),
      })).trigger();

      Upx.call('ShopModule', 'setSpecialEuTaxRateOnOrder', {
        tax_rate_id: iclTaxId,
        id: this.order.get('id'),
      }).then(
        () => {
          this.order.fetch().then(
            () => {
              const items = this.order.get('order_items') || [];
              items.forEach((item) => {
                const frID = item.extra.frontend_id;
                const frItem = this.orderItemCollection.get(frID);
                if (item.subitems && item.subitems.length) {
                  item.subitems.forEach((subitem) => {
                    frItem.setSubItemPpuWt(
                      subitem.extra.frontend_id,
                      subitem.ppu_wt,
                      '0',
                    );
                  });
                  frItem.getCalculatedSubItems();
                }
                frItem.set('tax', '0');
                if (item.before_discount_ppu_wt) {
                  frItem.setPpuWt(item.before_discount_ppu_wt.toFixed(2), false);
                  frItem.setDiscountPpuWt(
                    (item.before_discount_ppu_wt - item.ppu_wt).toFixed(2),
                  );
                } else {
                  frItem.setPpuWt(item.ppu_wt.toFixed(2), false);
                }
              });
              PaymentMethodItemCollection.setTotalPriceWt(
                this.orderItemCollection.getTotalPriceWt(),
              );
              this.renderPaymentList();
              this.renderTotalList();
              this.renderCustomer(false);
              this.iclModel.set('selected', true);
              orderDef.resolve();
            },
            orderDef.reject,
          );
        },
        (e) => {
          this.showError(e);
          orderDef.reject(e);
        },
      );
    } else {
      // queue it, something is already synchronising
      this.orderDef.always(
        () => {
          this.iclClicked();
        },
      );
    }
  },
  showError(error) {
    const view = new MessagePopupView();
    view.open(error);
  },

  onShow() {
    const def = this.createInitialOrder();
    this.setOrderSyncDeferred(def);

    // clear the payments, persisting them seems for give a lot of problems
    PaymentMethodItemCollection.clear();

    this.keyboardModel.resetMode();
    this.keyboardModel.on('change:mode', this.payCheck, this);
    PaymentMethodItemCollection.on('all', this.payCheck, this);
    this.orderItemCollection.on('all', this.payCheck, this);
    this.orderItemCollection.on('all', this.togglePartialDeliveryButton, this);
    this.orderItemCollection.on('change', this.renderProductList, this);
    SelectedCustomerModel.on('change:id', this.ensurePartialDelivery, this);
    SelectedCustomerModel.on('change:id', this.togglePartialDeliveryButton, this);
    SelectedCustomerModel.on('change:id', this.toggleIclButton, this);

    this.refundCheck();
    this.payCheck();
    this.togglePartialDeliveryButton();
    this.toggleIclButton();

    window.onbeforeunload = () => this.pageProtector();

    if (this.isQuick) {
      if (this.orderDef) {
        const loaderDef = new $.Deferred();
        (new FullScreenLoaderEvent({
          deferred: loaderDef,
          title: Locale.translate('starting_pin_payment'),
        })).trigger();

        this.orderDef.then(
          () => {
            loaderDef.resolve();
            this.doQuickPayment();
          },
          loaderDef.resolve,
        );
      } else {
        this.doQuickPayment();
      }
    }
  },

  doQuickPayment() {
    if (this.isReadyForPayments()) {
      if (PaymentMethods.pinEnabled()) {
        const toPay = PaymentMethodItemCollection.getLeftToPayWt();
        if (toPay > 0) {
          if (!PaymentMethodItemCollection.get(PaymentMethods.PIN_METHOD)) {
            this.availablePaymentView.togglePaymentMethod(PaymentMethods.PIN_METHOD);
          }
          if (this.isPayButtonEnabled()) {
            this.processOrderOnce();
          } else {
            this.logFailedQuickPayment();
            this.showError(Locale.translate('cannot_start_quick_payment'));
          }
        } else {
          this.showError(Locale.translate('pin_cannot_be_used_because_total_is_negative'));
        }
      } else {
        this.showError(Locale.translate('pin_payments_are_not_enabled_on_this_pos'));
      }
    } else {
      if (this.orderItemCollection.length === 0) {
        this.showError(Locale.translate('no_products_in_order'));
      }
      this.logFailedQuickPayment();
    }
  },

  logFailedQuickPayment() {
    console.error('Failed to start the quick payment', {
      order: this.order ? this.order.toJSON() : undefined,
      orderReady: this.orderSyncReady(),
      hasPaymentMethod: PaymentMethodItemCollection.length > 0,
      toPay: PaymentMethodItemCollection.getLeftToPayWt(),
      keyboardMode: this.keyboardModel.get('mode'),
    });
  },

  shopManualRefundPopup() {
    const view = new RefundPopupView({
      orderId: this.order.get('id'),
      paymentMethodCollection: PaymentMethodItemCollection,
    });
    view.open();
  },

  pageProtector() {
    const hasPaid = Payment.getTotalWtOfLockedItems(PaymentMethodItemCollection) !== '0.00';
    if (hasPaid) {
      this.shopManualRefundPopup();
      return true;
    }
    return null;
  },

  refundCheck() {
    const amount = this.orderItemCollection.getTotalPriceWt();
    const cashModel = PaymentMethodItemCollection.get(PaymentMethodItemCollection.CASH_METHOD);
    if (parseFloat(amount) <= 0 && !cashModel) {
      PaymentMethodItemCollection.addMethodById(PaymentMethodItemCollection.CASH_METHOD);
    }
  },

  onDestroy() {
    // clean protectors
    window.onbeforeunload = null;

    this.keyboardModel.off('change:mode', this.payCheck, this);
    PaymentMethodItemCollection.off('all', this.payCheck, this);
    this.orderItemCollection.off('all', this.payCheck, this);
    this.orderItemCollection.off('all', this.togglePartialDeliveryButton, this);
    this.orderItemCollection.off('change', this.renderProductList, this);

    // TODO [ian@8/14/20]: Remove when tabs are being remove
    if (this.tabId || this.tableId || this.repairId) {
      // when we check out the tab we should go back to empty order
      OrderItemCollection.clear();
    }
    SelectedCustomerModel.off('change:id', this.syncOrderCustomer, this);
    SelectedCustomerModel.off('change:id', this.ensurePartialDelivery, this);
    SelectedCustomerModel.off('change:id', this.togglePartialDeliveryButton, this);
    SelectedCustomerModel.off('change:id', this.toggleIclButton, this);

    // cancel the order on leaving
    LastOrdersCollection.cancelAllUnprocessedOrders();
  },

  togglePartialDeliveryButton() {
    // Check if there is a delivery button
    if (!this.isDestroyed) {
      const enableButton = SelectedCustomerModel.has('id')
        && this.orderItemCollection.length > 0 // has any products in the order.
        && this.order // initial order is save
        && this.orderSyncReady(); // there is no order saving running

      this.ui.delivery.attr('disabled', !enableButton);
    }
  },

  toggleIclButton() {
    // Check if there is a delivery button
    if (!this.isDestroyed) {
      const enableButton = SelectedCustomerModel.has('id')
          && this.orderItemCollection.length > 0 // has any products in the order.
          && this.order // initial order is save
          && this.orderSyncReady(); // there is no order saving running

      this.iclModel.set('disabled', !enableButton);
    }
  },

  ensurePartialDelivery() {
    if (!SelectedCustomerModel.has('id')) {
      this.orderItemCollection.each((model) => {
        model.resetShipped();

        const currentOrderItem = OrderItemCollection.get(model.get('id'));
        currentOrderItem.resetShipped();
        currentOrderItem.save();
      });
    }
  },

  syncOrderCustomer() {
    let relationId = 0;
    if (SelectedCustomerModel.get('id')) {
      relationId = SelectedCustomerModel.get('id');
    }
    const currentCustomerId = this.order.get('relation_data_id');
    if (currentCustomerId !== SelectedCustomerModel.get('id')) {
      if (this.orderSyncReady()) {
        // Setup deferred and update the order.
        const def = new $.Deferred();
        this.order.updateWithoutItems({
          fields: {
            relation_data_id: relationId,
            is_anonymous: !relationId,
          },
          id: this.order.get('id'),
        }).then(
          () => {
            // unset relation_data to ensure its the latest data
            this.order.unset('shipping_address');
            this.order.unset('billing_address');
            this.order.unset('relation_data_id');
            this.order.unset('relation_data');
            // Update order data
            this.order.fetch()
              .then(def.resolve, def.reject);
          },
          (resp) => {
            // rollback to previous customer
            if (currentCustomerId) {
              SelectedCustomerModel.selectByRelationDataId(currentCustomerId);
            } else {
              // anonymous
              SelectedCustomerModel.unload();
            }
            // Reject deferred
            def.reject(resp);
          },
        );
        this.setOrderSyncDeferred(def);
      } else {
        // queue it, something is already synchronising
        this.orderDef.always(
          () => {
            this.syncOrderCustomer();
            this.payCheck();
            this.togglePartialDeliveryButton();
          },
        );
      }
    }
  },

  syncDescription() {
    const description = this.ui.description.val();
    const currentDescription = this.order.get('description');
    if (currentDescription !== description) {
      if (this.orderSyncReady()) {
        const def = this.order.updateWithoutItems({
          fields: {
            description,
            invoice_notes: description,
          },
          id: this.order.get('id'),
        }).then(
          () => {
            this.order.set('description', description);
          },
        );
        this.setOrderSyncDeferred(def);
      } else {
        // queue it, something is already synchronising
        this.orderDef.always(
          () => {
            this.syncDescription();
          },
        );
      }
    }
  },

  orderSyncReady() {
    const state = this.orderDef.state();
    return state === 'resolved' || state === 'rejected'; // def finished or failed, but at least not working
  },

  setOrderSyncDeferred(def) {
    this.orderDef = def;
    this.payCheck();
    this.togglePartialDeliveryButton();
    this.toggleIclButton();
    this.orderDef.always(() => {
      this.payCheck();
      this.togglePartialDeliveryButton();
      this.toggleIclButton();
    });
    return this.orderDef;
  },

  isPayButtonEnabled() {
    const toPay = PaymentMethodItemCollection.getLeftToPayWt();
    const hasPaymentMethod = PaymentMethodItemCollection.length > 0;
    // if no payment methods we go for cash return
    return this.isReadyForPayments()
            && parseFloat(toPay) <= 0 && hasPaymentMethod;
  },

  isReadyForPayments() {
    const hasProducts = this.orderItemCollection.length > 0;
    const usingKeyboard = (
      this.keyboardModel.isModeWithConfirmation()
        // payments are beeing set on press down, so we can ignore
        && this.keyboardModel.get('mode') !== this.keyboardModel.MODE_PAYMENT_VALUE
    );
    return hasProducts
        && !usingKeyboard
        && this.orderSyncReady() // there is no order saving running
        && this.orderCreated; // order was created
  },

  payCheck() {
    if (!_.isString(this.ui.pay)) {
      this.ui.pay.attr('disabled', !this.isPayButtonEnabled());
    }
  },

  payClicked: _.debounce(function () {
    this.processOrderOnce();
  }, 100),

  shippingMethodCollection: null,
  getShippingMethodCollection() {
    const def = new $.Deferred();

    if (this.shippingMethodCollection && this.shippingMethodCollection.length > 0) {
      def.resolve(this.shippingMethodCollection);
    } else {
      this.shippingMethodCollection = new ShippingMethodCollection();
      const params = {
        start: 0,
        limit: 20,
        filters: [
          {
            name: 'shipping_type/module_name__=',
            val: 'ShippingModule',
          },
          {
            name: 'shipping_type/alias__=',
            val: 'PickupAtStore',
          },
        ],
      };

      this.shippingMethodCollection.fetchAll({ params })
        .then(() => def.resolve(this.shippingMethodCollection), def.reject);
    }

    return def;
  },

  deliveryClicked: _.debounce(function () {
    const def = new $.Deferred();
    (new FullScreenLoaderEvent({
      deferred: def,
      title: Locale.translate('loading_pickup_methods'),
    })).trigger();
    this.getShippingMethodCollection()
      .then((shippingMethodCollection) => {
        def.resolve();
        const view = new PartialPopupView({
          shippingMethodCollection,
          orderModel: this.order,
          orderItemCollection: this.orderItemCollection,
        });
        view.open().always(() => {
          // popup closed -> transfer to global orderItemCollection
          this.orderItemCollection.each(
            (orderItem) => {
              const currentOrderItem = OrderItemCollection.get(orderItem.get('id'));
              currentOrderItem.unset('to_be_shipped_serial_nos', { silent: true });
              currentOrderItem.set({
                unfulfilled_quantity: orderItem.get('unfulfilled_quantity'),
                to_be_shipped_quantity: orderItem.get('to_be_shipped_quantity'),
                to_be_shipped_serial_nos: orderItem.get('to_be_shipped_serial_nos') || [],
              });
              currentOrderItem.save();
            },
          );
        });
      }, def.reject);
  }, 100),

  navigateToSuccess(logCollection, payments) {
    window.onbeforeunload = null; // clean the protector, so we can always leave the page
    LastOrdersCollection.markAsSuccess(this.order, logCollection, payments);
    Backbone.history.navigate('success', { trigger: true });
  },

  processOrderOnce() {
    if (!this.isProcessingOrder) {
      try {
        this.isProcessingOrder = true;
        const paymentProcessingDef = this.processOrder();
        paymentProcessingDef.always(
          () => this.isProcessingOrder = false,
        );
      } catch (e) {
        this.isProcessingOrder = false; // make sure it gets to false, always
        throw e;
      }
    } else {
      console.warn('Order is already processing');
    }
  },

  silentlyApplyOrderItemIds() {
    const items = this.order.get('order_items') || [];
    items.forEach((item) => {
      if (item.extra && item.extra.frontend_id) {
        const currentItem = this.orderItemCollection.get(item.extra.frontend_id);
        currentItem.set({
          order_item_id: item.id,
        }, { silent: true });
        // This must be silent to prevent unwanted UI updated.
        // This one can be done silently, because it does not influence anything in the current UI
        // It it only used to create the giftCards with the correct order_item_id attached it it.
      }
    });
  },

  processOrder() {
    const def = new $.Deferred();
    const loaderDef = new $.Deferred();

    CashRegisterApi.logAction('VIEW_BUTTON_CLICKED', {
      button: 'pay',
    });
    const processingView = new ProcessingCollectionView({
      number: this.order.get('number'),
      type: ProcessingCollectionView.TYPE_NEW_ORDER,
    });
    const cashierDisplay = new CashierDisplayView();
    const event = new FullScreenLoaderEvent({
      deferred: loaderDef,
      statusView: processingView,
      cashierDisplay,
      title: Locale.translate('processing_order_{0}', this.order.get('number')),
      // loaderType: 'progress',
      extraClassName: 'payments-processing',
    });
    event.trigger();

    const orderId = this.order.get('id');
    if (this.tableId) {
      // set table so Order.processOrder, won't production receipts
      this.order.set('table_id', this.tableId);
    }
    if (this.repairId) {
      this.order.set('repair_id', this.repairId);
    }

    this.silentlyApplyOrderItemIds();

    const fullLog = processingView.full(this.order);
    Order.saveToLocalStorage();
    Order.processOrder(this.order, processingView, cashierDisplay).then(
      (payments) => {
        const receiptData = Printing.createReceiptJson(this.order, PaymentMethodCollection);

        setTimeout(() => {
          CashRegisterApi.logAction('ORDER_CREATED', {
            order_id: orderId,
          });

          // TODO [ian@8/14/20]: Remove when tabs are being remove
          if (this.tabId) {
            TabCollection.clearTab(this.tabId);
          }

          // Saving stuff that is not important now.
          // So we can save it async.
          Order.saveOrderReceiptJob(orderId, receiptData);
        });

        this.order.set('order_vars', [{
          alias: 'receipt_escpos',
          value: receiptData,
        }]);


        const pinPayment = payments.find((model) => {
          const paymentMethod = model.get('paymentMethod');

          return paymentMethod && paymentMethod.id === PaymentMethods.PIN_METHOD;
        });

        if (pinPayment && TerminalSetting.isCCVPin()) {
          // CCV pin receipts are required to be printed immediately
          Printing.printReceipt(this.order); // TODO: Onfail?

          // Popup
          const { requestIdentification, requestMerchantSignature, requestSignature } = pinPayment.get('paymentMethod');

          if (requestIdentification || requestMerchantSignature || requestSignature) {
            const view = new MessagePopupView();

            let text = '';
            if (requestIdentification && !requestSignature) {
              // Customer should be asked for an ID
              text = Locale.translate("ask_for_identification");
            } else if (requestIdentification && requestSignature) {
              // Customer should be asked for ID and to place a signature
              text = Locale.translate("ask_for_identification_and_signature");
              // Customer should be asked to place a signature
            } else if (requestSignature && !requestIdentification) {
              text = Locale.translate("ask_for_signature");
            } else if (requestMerchantSignature) {
              // Cashier should place a signature
              text = Locale.translate("place_signature");
            }

            view.open(text);
          }
        }

        def.resolve(payments);
      },
      (error) => {
        this.showError(error);
        def.reject(error);
      },
    );

    def.then((payments) => {
      if (RefundOrderComponent.has()) {
        // Handle refund checkout
        const log = processingView.refund();
        $.when(
          Order.newOrderLogComment(
            RefundOrderComponent.get().get('id'),
            Locale.translate('there_are_returned_products_from_this_order'),
            Locale.translate('return_order_number_{0}', this.order.get('number')),
          ),
          Order.newOrderLogComment(
            orderId,
            Locale.translate('return_order_created'),
            Locale.translate('original_order_number_{0}', RefundOrderComponent.get().get('number')),
          ),
        ).then(
          () => {
            log.success();
            RefundOrderComponent.clear();
            loaderDef.resolve(payments);
          },
          (error) => {
            log.error(error);
            console.error(error);
            CashRegisterApi.logAction('ORDER_ERROR_ON_SETTING_REFUND', {
              error,
            });
            loaderDef.resolve(payments);
          },
        );
      } else if (this.tableId) {
        // Handle table finalizing
        const tableDeferred = new $.Deferred();
        // Handle deferred events
        tableDeferred.then(() => {
          loaderDef.resolve(payments);
        }, (resp) => {
          const message = Locale.translate('unable_to_finalize_order_on_table');
          Toaster.error(message, resp.error);
          loaderDef.reject(message);
        });

        // Check if the table exists
        const tableModel = Tables.get(this.tableId);
        if (tableModel) {
          // Create tableOrder model to finalize
          const tableOrderModel = new TableOrderModel({
            id: tableModel.get('table_order_id'),
            order_id: orderId,
          });

          // Finalize
          tableOrderModel.finalize().then(tableDeferred.resolve, tableDeferred.reject);
        } else {
          // Table not found
          const error = Locale.translate('unknown_table_with_id_{table_id}', { table_id: this.tableId });
          tableDeferred.reject({ error });
        }
      } else if (this.repairId) {
        const repairDeferred = Repair.finaliseRepair(orderId, this.repairId);
        repairDeferred.then(
          () => {
            loaderDef.resolve(payments);
          },
          (resp) => {
            const message = Locale.translate('unable_to_finalize_the_repair');
            Toaster.error(message, resp.error);
            loaderDef.reject(message);
          },
        );
      } else {
        // Handle regular checkout
        loaderDef.resolve(payments);
      }
    }, (error) => {
      if (error.refundError) {
        loaderDef.reject(error.refundError.error);
      } else {
        loaderDef.reject(error.error);
      }
    });

    loaderDef.then(
      (payments) => {
        fullLog.success();
        processingView.stopAllTimes();

        this.navigateToSuccess(processingView.collection, payments);
      },
      (error) => {
        fullLog.error(error);
        processingView.stopAllTimes();
      },
    );
    return loaderDef.promise();
  },

  onRender() {
    this.renderCustomer();
    this.renderProductList();
    this.renderProductTotals();
    this.renderKeypad();
    this.renderAvailablePayment();
    this.renderPaymentList();
    this.renderTotalList();

    SelectedCustomerModel.on('change:id', this.syncOrderCustomer, this);
  },

  renderCustomer(allowChange = true) {
    const region = this.getRegion('customer');
    const view = new CustomerSelectionView({
      keyboardModel: this.keyboardModel,
      allowChange,
      applyPermanentDiscountPopup: false,
    });
    region.show(view);
  },

  renderProductList() {
    const hasDeliverableProducts = this.orderItemCollection
      .filter((model) => {
        const { to_be_shipped_quantity, quantity } = model.toJSON();
        return parseInt(to_be_shipped_quantity, 10) !== parseInt(quantity, 10);
      })
      .length > 0;

    const region = this.getRegion('product-list');
    const view = new ProductListView({
      columns: [
        hasDeliverableProducts ? 'deliverQuantity' : 'quantity',
        'description',
        'addonTotal',
      ],
      editable_columns: [],
      itemPriceWithName: true,
      keyboardModel: this.keyboardModel,
      collection: this.orderItemCollection,
    });
    region.show(view);
  },

  renderProductTotals() {
    const region = this.getRegion('product-totals');
    const view = new ProductTotalsView({
      show_back_button: true,
      orderItemCollection: this.orderItemCollection,
    });
    region.show(view);
    view.on('subtotal:clicked', () => {
      Backbone.history.navigate('checkout', { trigger: true });
    });
  },

  renderAvailablePayment() {
    const region = this.getRegion('payment-methods');

    const view = new AvailablePaymentView({
      keyboardModel: this.keyboardModel,
      iclModel: ShopPos.get('allow_icl_checkout') ? this.iclModel : null,
      orderModel: this.order,
    });
    region.show(view);
    this.availablePaymentView = view;
  },

  renderPaymentList() {
    const region = this.getRegion('payment-list');
    const view = new PaymentListView({
      shopManualRefundPopup: () => this.shopManualRefundPopup(),
    });
    region.show(view);
  },

  renderTotalList() {
    const region = this.getRegion('payment-totals');
    const view = new PaymentTotalView();
    region.show(view);
  },

  renderKeypad() {
    const region = this.getRegion('keypad');
    const view = new KeypadPercentageView({
      model: this.keyboardModel,
    });
    region.show(view);
  },

}));
